import clsx from 'clsx';
import { currentUserSelector } from 'features/auth/selector';
import useGetUserAvatar from 'layouts/MainLayout/helpers/useGetUserAvatar';
import { useAppSelector } from 'lib/stores/hook';
import { memo } from 'react';

const UserAvatar = () => {
  const currentUser = useAppSelector(currentUserSelector);

  const { displayName, userName } = currentUser || {};

  const { userAvatar, roleLabel } = useGetUserAvatar();

  return (
    <figure className="relative">
      <img
        src={userAvatar}
        alt={displayName ?? userName ?? 'User avatar'}
        className="mb-6 block h-24 w-24 rounded-full object-cover"
      />
      {roleLabel && (
        <figcaption
          className={clsx(
            'absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2',
            'rounded-full bg-primary px-2 py-1',
            'whitespace-nowrap text-xs text-white'
          )}
        >
          {roleLabel}
        </figcaption>
      )}
    </figure>
  );
};

export default memo(UserAvatar);
